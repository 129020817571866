import React from 'react';
import { Error } from 'components/error';
import { NotFound } from 'components/error/NotFound';
import PropTypes from 'prop-types';

const ErrorPage = ({ statusCode }) => {
  return <>{statusCode && statusCode === 404 ? <NotFound /> : <Error />}</>;
};

ErrorPage.getInitialProps = ({ res, err }) => {
  // eslint-disable-next-line
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  return { statusCode };
};

ErrorPage.propTypes = {
  statusCode: PropTypes.number
};

export default ErrorPage;
