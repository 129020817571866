import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import BlankLayout from '../../layouts/BlankLayout';

const Error = props => {
  if (props.error) {
    console.log(`Error:  ${props.error}`);
  }

  return (
    <Container>
      <StyledIcon icon={faExclamationTriangle} />
      <Text>An error has occurred. Please refresh and try again.</Text>
      <Text>
        If the problem persists, please{' '}
        <Link
          href="https://help.acloud.guru/hc/en-us/requests/new?"
          target="_blank"
        >
          contact support
        </Link>
        .
      </Text>
    </Container>
  );
};

Error.propTypes = {
  error: PropTypes.string
};

const Container = styled(BlankLayout)`
  margin-top: 20px;
  text-align: center;
`;

const Link = styled.a`
  text-decoration: underline;
  color: #fff;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 6em;
  margin-bottom: 0.2em;
`;

const Text = styled.div`
  font-size: 1.25em;
`;

export { Error };
